import './App.css';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import axios from 'axios';
import ABI from './ABI.json';
import VAULTABI from './VAULTABI.json';
import { NFTCONTRACT, STAKINGCONTRACT, polygonscanapi, moralisapi } from './config';
import Web3Modal from "web3modal";
import Web3 from "web3";
import ReactGA from 'react-ga';
import CookieConsent from "react-cookie-consent";

var account = null;
var contract = null;
var vaultcontract = null;
var web3 = null;
const gasOptions = { gasPrice: 97854963264, gasLimit: 500000 };

const moralisapikey = "JwcyI3kFcZIx9M9Psom0b0d2ahdb9VxmSs80MnYU43bbwX803Jufh0XzqYKbXzyg";
const polygonscanapikey = "JAR6HQQDTVZ3UQIHCUFHJ7MV2M5E6V2FFE";

const providerOptions = {
};


const web3Modal = new Web3Modal({
    network: "mainnet",
    theme: "dark",
    cacheProvider: false,
    providerOptions
});

class App extends Component {
    constructor() {
        super();
        this.state = {
            balance: [],
            rawearn: [],
        };
    }

    handleModal() {
        this.setState({ show: !this.state.show })
    }

    handleNFT(nftamount) {
        this.setState({ outvalue: nftamount.target.value });
    }

    async componentDidMount() {

        // Adding Google Analytics
        ReactGA.initialize('UA-236874074-1');
        ReactGA.pageview(window.location.pathname + window.location.search);

        //   Adding Facebook Pixels
        import('react-facebook-pixel')
            .then((x) => x.default)
            .then((ReactPixel) => {
                ReactPixel.init('371285161139798');
                ReactPixel.pageView();
            });

        await axios.get((polygonscanapi + `?module=stats&action=tokensupply&contractaddress=${NFTCONTRACT}&apikey=${polygonscanapikey}`))
            .then(outputa => {
                this.setState({
                    balance: outputa.data
                })
                console.log(outputa.data)
            })
        let config = { 'X-API-Key': moralisapikey, 'accept': 'application/json' };
        await axios.get((moralisapi + `/nft/${NFTCONTRACT}/owners?chain=polygon&format=decimal`), { headers: config })
            .then(outputb => {
                const { result } = outputb.data
                this.setState({
                    nftdata: result
                })
                console.log(outputb.data)
            })
    }

    render() {
        async function connectwallet() {
            var provider = await web3Modal.connect();
            web3 = new Web3(provider);
            await provider.request({ method: 'eth_requestAccounts' });
            var accounts = await web3.eth.requestAccounts();
            account = accounts[0];
            document.getElementById('wallet-address').textContent = account;
            contract = new web3.eth.Contract(ABI, NFTCONTRACT);
            vaultcontract = new web3.eth.Contract(VAULTABI, STAKINGCONTRACT);
            var getstakednfts = await vaultcontract.methods.tokensOfOwner(account).call();
            document.getElementById('yournfts').textContent = getstakednfts;
            var getbalance = Number(await vaultcontract.methods.balanceOf(account).call());
            document.getElementById('stakedbalance').textContent = getbalance;
            const arraynft = Array.from(getstakednfts.map(Number));
            const tokenid = arraynft.filter(Number);
            var rwdArray = [];
            tokenid.forEach(async (id) => {
                var rawearn = await vaultcontract.methods.earningInfo(account, [id]).call();
                var array = Array.from(rawearn.map(Number));
                console.log(array);
                array.forEach(async (item) => {
                    var earned = item.toPrecision(22).split('.')[0]
                    var earnedrwd = Web3.utils.fromWei(earned);
                    var rewardx = Number(earnedrwd).toFixed(2);
                    var numrwd = Number(rewardx);
                    console.log(numrwd);
                    rwdArray.push(numrwd);
                });
            });
            function delay() {
                return new Promise(resolve => setTimeout(resolve, 300));
            }
            async function delayedLog(item) {
                await delay();
                var sum = item.reduce((a, b) => a + b, 0);
                var formatsum = Number(sum).toFixed(2);
                document.getElementById('earned').textContent = formatsum;
            }
            async function processArray(rwdArray) {
                for (const item of rwdArray) {
                    await delayedLog(item);
                }
            }
            return processArray([rwdArray]);
        }

        async function enable() {
            contract.methods.setApprovalForAll(STAKINGCONTRACT, true).send({ from: account, ...gasOptions });
        }
        async function rewardinfo() {
            var rawnfts = await vaultcontract.methods.tokensOfOwner(account).call();
            const arraynft = Array.from(rawnfts.map(Number));
            const tokenid = arraynft.filter(Number);
            var rwdArray = [];
            tokenid.forEach(async (id) => {
                var rawearn = await vaultcontract.methods.earningInfo(account, [id]).call();
                var array = Array.from(rawearn.map(Number));
                array.forEach(async (item) => {
                    var earned = item.toPrecision(22).split('.')[0];
                    var earnedrwd = Web3.utils.fromWei(earned);
                    var rewardx = Number(earnedrwd).toFixed(2);
                    var numrwd = Number(rewardx);
                    rwdArray.push(numrwd)
                });
            });
            function delay() {
                return new Promise(resolve => setTimeout(resolve, 300));
            }
            async function delayedLog(item) {
                await delay();
                var sum = item.reduce((a, b) => a + b, 0);
                var formatsum = Number(sum).toFixed(2);
                document.getElementById('earned').textContent = formatsum;
            }
            async function processArray(rwdArray) {
                for (const item of rwdArray) {
                    await delayedLog(item);
                }
            }
            return processArray([rwdArray]);
        }
        return (
            <div className="container py-2 bg-black">
                <div className="App nftapp bg-black">
                    <div className="container">


                    <h1 style={{ color: "#D53790", fontFamily: "Avenir", fontWeight: "800", fontSize: "35px" }}>ATENTION!</h1>
                    <h5 style={{ color: "#ffffff", fontFamily: "Avenir", fontWeight: "800", fontSize: "20px" }}>
                        This page had an update. You can still unstake your MetaBadges through here. <br></br>
                        Click the button below if you want to access the newest version and use all staking features</h5>
                <Button id="connectbtn" type="button" className="btn mt-3"
                    style={{ backgroundColor: "#D53790", borderRadius: "100px", borderColor: "#D53790", color: "#ffffff", fontFamily: "Avenir", fontWeight: "800", fontSize: "18px" }}
                    href="https://staking.osisplatform.com" rel="noreferrer">Take me to the new staking dapp</Button>
                        {/* <input id="connectbtn" type="button" className="connectbutton" onClick={connectwallet} style={{ backgroundColor: "#26A7DF", className: "nav-item d-flex align-content-center flex-wrap" }} value="Connect Your Wallet" /> */}
                    </div>
                    <div className=', '>
                        <div className='col, py-4'>
                            <div className=''>
                                <div>
                                    <div className="row">
                                        <div>
                                            <h1 className="pt-3 MStakingP" >Legacy MetaBadge Staking Platform</h1>
                                            {/* <h1 className="pt-3" style={{ fontWeight: "30", color: "#ffffff", fontFamily: "Avenir" }}>MetaBadge Staking Platform</h1> */}
                                        </div>
                                        <h6 style={{ fontWeight: "800", color: "#ffffff", fontFamily: "Avenir" }}>Your Wallet Address</h6>
                                        <div className="pb-1" id='wallet-address' style={{
                                            fontSize: "16px",
                                            color: "#4AC1E8",
                                            fontWeight: "800",
                                            // textShadow: "1px 1px 1px white",
                                        }}>
                                            <Button id="connectbtn" type="button" className="btn mt-1"
                                                style={{ backgroundColor: "#61dafb", borderRadius: "100px", borderColor: "#61dafb", color: "#ffffff", fontFamily: "Avenir", fontWeight: "800", fontSize: "18px" }}
                                                onClick={connectwallet} htmlFor="floatingInput">Connect your wallet to unstake</Button>
                                            {/* <label type="button" onClick={connectwallet} htmlFor="floatingInput">Please Connect Your Wallet</label> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col bg-black'>
                        <div className='nftstaker border-0 bg-black'>
                            <div style={{ fontFamily: "Avenir LT Std" }} >
                                <h2 className="pt-2" style={{ fontFamily: "Avenir LT Std", borderRadius: '14px', fontWeight: "400", color: "#ffffff", fontSize: "25px" }} id="Vault">MetaBadge Staking Vault </h2>
                                {/* <h6 style={{ fontWeight: "300", color: "#ffffff", fontFamily: "Avenir LT Std" }}>First time staking?</h6> */}
                                {/* <Button className="btn" onClick={enable}
                                    style={{ backgroundColor: "#26A7DF", boxShadow: "1px 1px 5px #4ac0e7", color: "#ffffff", fontFamily: "Avenir LT Std" }}
                                >Authorize Your Wallet</Button> */}
                                <div className="row mt-5 px-3 pt-1">
                                    <div className="col-sm-4 col-center-block">
                                        <div className="stakingrewards" style={{ borderRadius: "25px", boxShadow: "1px 1px 15px #4ac0e7" }}>
                                            <h5 style={{ color: "#FFFFFF", fontSize: "15px", fontWeight: '300', paddingTop: 15 }}>OSIS Vault</h5>
                                            <table className='table mb-5 px-3 table-dark wrap-nft'>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ fontSize: "0px", border: "transparent", background: 'transparent', paddingTop: 0, paddingBottom: 0 }}>
                                                            <span style={{ backgroundColor: "#ffffff00", fontSize: "0px", color: "#D53790", fontWeight: "0", textShadow: "1px 1px 2px #000000" }} id='yournfts'></span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontSize: "19px", border: "transparent", background: 'transparent', paddingTop: 1, paddingBottom: 1 }}>Total Staked NFTs:<br></br>
                                                            <span style={{ backgroundColor: "#ffffff00", fontSize: "21px", color: "#D53790", fontWeight: "500", textShadow: "1px 1px 2px #000000" }} id='stakedbalance'>Total Staked</span>
                                                        </td>
                                                    </tr>
                                                    {/* <tr> */}
                                                    {/* <td style={{ fontSize: "19px", background: 'transparent' }}> */}
                                                    {/* <Button onClick={unstakeall}
                                                            style={{ backgroundColor: "#D53790", boxShadow: "1px 1px 5px #4ac0e7", color: "#ffffff", fontFamily: "Avenir LT Std"  }}className='mb-4'
                                                            >Unstake All</Button> */}
                                                    {/* </td> */}
                                                    {/* </tr> */}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <img className="img-fluid col-sm-2 col-center-block mb-5" src="metabadges.png" width="" height="" alt="Responsive" />
                                    <div className="col-sm-4 col-center-block">
                                        <div className='stakingrewards' style={{ borderRadius: "25px", boxShadow: "1px 1px 15px #4ac0e7", fontFamily: "Avenir LT Std" }}>
                                            <h5 style={{ color: "#FFFFFF", fontSize: "15px", fontWeight: '300', paddingTop: 15 }}>Unstake to Claim Rewards</h5>
                                            <div style={{ border: "transparent", background: "transparent" }} >Refresh OSIS Rewards
                                                <button style={{ border: "none", background: "transparent" }} >
                                                    <img src="refresh.png" width="" height="15" background="transparent" border="transparent" alt="" onClick={rewardinfo} />
                                                </button>
                                            </div>
                                            <div id='earned' style={{ color: "#D53790", fontSize: '21px', fontWeight: '500', textShadow: "1px 1px 2px #000000" }}><p >Earned Tokens</p>
                                            </div>
                                            <div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row px-4 pt-2">
                                    <div className="header">
                                        <div className='col bg-black'></div>
                                        <div style={{ fontSize: '25px', borderRadius: '14px', color: "#ffffff", fontWeight: "300" }}>Staking Pool Active Rewards</div>
                                        <h1>
                                        </h1>
                                        <table style={{ boxShadow: "1px 1px 15px #4ac0e7" }} className='table px-3 table-bordered table-dark' >
                                            <thead className='thead-light'>
                                                <tr>
                                                    <th scope="col">Collection</th>
                                                    <th scope="col">Rewards Per Day</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Standard MetaBadge</td>
                                                    <td className="amount" data-test-id="rewards-summary-ads">
                                                        <span className="amount">1</span>&nbsp;<span className="currency">OSIS</span>
                                                    </td>
                                                </tr>
                                                <tr className='stakegoldeffect'>
                                                    <td>Rare / Super Rare MetaBadge</td>
                                                    <td className="amount " data-test-id="rewards-summary-ac">
                                                        <span className="amount">2</span>&nbsp;<span className="currency">OSIS</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {/* <a href="/OSIS_Rewards.pdf" target="_blank" rel="noreferrer"
                                            className="'mb-3 mt-3 bg-black'"
                                            style={{ backgroundColor: "#000000", boxShadow: "0px 0px 0px #4ac0e7", fontWeight: "300", fontSize: '20px', color: "#ffffff", fontFamily: "Avenir LT Std" }}>
                                            OSIS Rewards List
                                        </a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col mt-3 bg-black' id="NFT">
                        <div className='col bg-black'>
                            <h1 className=' mb-3 mt-5 bg-black'>MetaBadge Portal</h1>
                            <div className='col mt-3 ml-3 bg-black'>
                                <img src="polygon.png" width={'15%'} height="" alt="" ></img>
                            </div>
                            <CookieConsent
                                location="bottom"
                                buttonText="I accept"
                                expires={999}
                                cookieName="GDPR Compliance"
                                debug
                                // acceptOnScroll
                                declineButton
                                flipButtons
                                style={{ background: 'black', justifyContent: 'center' }}
                                buttonStyle={{
                                    marginTop: '0px',
                                }}
                                contentStyle={{ flex: '0 0 100%', textAlign: 'center' }}
                            // onAccept={({ acceptedByScrolling }) => {
                            //     if (acceptedByScrolling) {
                            //         // triggered if user scrolls past threshold
                            //         alert('Accept to continue scrolling');
                            //     } else {
                            //         alert('Accept to continue scrolling');
                            //     }
                            // }}
                            >This website uses cookies to enhance the user experience.<br></br>
                                <span style={{ fontSize: "12px" }}>©Copyright 2022 | Apotheosis | All Rights Reserved</span>{' '}
                            </CookieConsent>
                            {/* )} */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default App;
